// ----------student work------------------------


const StudWork = [

    {
      id: 1,
      List:
      [
        {
       Tname:"Aarti Per Day",
       
       Tvalue:"₹ 11000",
      },
      {
        Tname:"Bhog Per Day",
       
        Tvalue:"₹ 11000",
      },
      {
        Tname:" srifal Sponsor",
       
        Tvalue:"₹ 21,000",
      },
      {
        Tname:" Kalash Yatra Rath",
       
        Tvalue:"₹ 21,000",
      },
      {
        Tname:" Kalash Yatra Pothi",
       
        Tvalue:"₹ 51,000",
      },
      {
        Tname:"Kalash Yatra Saree 100 qty",
       
        Tvalue:"₹ 51,000",
      },
      {
        Tname:" Kalash Yatra Refreshment",
       
        Tvalue:"₹ 51,000",
      },
      {
        Tname:"500 Meals",
       
        Tvalue:"₹ 21,000",
      },
      {
        Tname:"1000 Meals",  
       
       
        Tvalue:"₹ 41,000",
      },
      ]

    },
    
    {
      id: 2,
      List:
      [
        {
       Tname:"1500 Meals",
       
       Tvalue:"₹ 61,000",
      },
      {
        Tname:"2000 Meals",
       
        Tvalue:"₹ 81,000",
      },
      {
        Tname:"2500 Meals",
       
        Tvalue:"₹ 1,00,000",
      },
      {
        Tname:"1 Day Meal",
       
        Tvalue:"₹ 1,51,000",
      },
      {
        Tname:"Water Donation Per Day",
       
        Tvalue:"₹ 11,000",
      },
      {
        Tname:"Hawan Sponsor",
       
        Tvalue:"₹ 51,000",
      },
      {
        Tname:" Hawan Day Refreshment",
       
        Tvalue:"₹ 51,000",
      },
      
      ]

    },
    {
      id: 2,
      List:
      [
        {
       Tname:"Advertisement Arc Gate ",
       Tname1:"Top Arc 30ft x 3ft-2Nos & Side Pillars 12ft x3ft-3Nos",
       Tname2:"Ad on Video Wall & 25000 Leaflets",
       Tvalue:"₹ 1,00,000",
      },
      {
        Tname:"2000 Meals",
        Tname1:"Top Arc 30ft x 3ft-1 Nos & Side Pillars 12ft x3ft-2Nos",
        Tname2:"Ad on Video Wall & 25000 Leaflets",
        Tvalue:"₹ 51,000",
      },
      {
        Tname:"2500 Meals",
        Tname1:"Side Pillars 12ft x3ft-2Nos",
        Tname2:"Ad on Video Wall & 25000 Leaflets",
        Tvalue:"₹ 25,000",
      },
      
      
      ]

    },
    
  ];
  export default StudWork;