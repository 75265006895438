import React, { useState } from 'react';
import "../CSS/Contact.css";
import $ from "jquery";
import {Link } from "react-router-dom";
import Footer from '../Components/Footer';


const Contact = () => {
    const [errors, setErrors] = useState({
        custName: "",
        custEmail: "",
        custContact: "",
        custMessage: "",
      });
    
      const handleValidation = () => {
        let isValid = true;
        const newErrors = {
          custName: "",
          custEmail: "",
          custContact: "",
          custMessage: "",
        };
        if (!custName.trim()) {
          isValid = false;
          newErrors.custName = "Name is required";
        }
    
        const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        if (!custEmail.match(emailRegex)) {
          isValid = false;
          newErrors.custEmail = "Email is not valid";
        }
    
        if (!custContact.trim()) {
          isValid = false;
          newErrors.custContact = "Phone is required";
        } else if (!/^\d{10}$/.test(custContact)) {
          isValid = false;
          newErrors.custContact = "Phone must have 10 digits";
        }
    
        if (!custMessage.trim()) {
          isValid = false;
          newErrors.custMessage = "Write a Message";
        }
    
        setErrors(newErrors);
        return isValid;
      };
      // ============ Mail function ==========
      const [custName, setCustName] = useState("");
      const [custEmail, setCustEmail] = useState("");
      const [custContact, setCustContact] = useState("");
      const [custMessage, setCustMessage] = useState("");
      // ============== Mail Print ======
      const ServForm = (e) => {
        e.preventDefault();
        if (handleValidation()) {
          var body =
            '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#6e3b70">JRB katha Vasai</h2><p>Hare Krishna,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#6e3b70">' +
            custName +
            '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#6e3b70">' +
            custEmail +
            '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#6e3b70">' +
            custContact +
            '</td></tr><tr><td style="text-align:left"><strong>Total People:</strong></td><td style="text-align:left;color:#6e3b70">' +
            custMessage +
            '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#6e3b70;padding-left:5px">Shree Krishna Digital Marketing</span>.</p></div></body></html>';
          $.post(
            "https://skdm.in/server/v1/send_lead_mail.php",
            {
              toEmail: "bhagwat.vnvasai@gmail.com",
              fromEmail: "skdmlead@gmail.com",
              bccMail: "skdmlead@gmail.com",
              mailSubject: "New Customer Lead",
              mailBody: body,
              accountName: "katha",
              accountLeadSource: "",
              accountLeadName: custName,
              // accountLeadEmail: custEmail,
            },
            function (dataa, status) {
              console.log("data :" + dataa);
              console.log("name:" + custName);
              console.log("name:" + custEmail);
            }
          );
    
          alert("Your Form has Submitted Our team will contact with You  soon.");
          e.preventDefault();
          return false;
        }
      };
        // popup Modal
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      {/* ====== contact Banner ======== */}
      <div className="contact-banner">
        <div className="contactTxt">
          <h2>Contact</h2>
          <ul>
            <li>
              <a href="#">Home</a> /
            </li>
            <li>Contact</li>
          </ul>
        </div>
      </div>
     {/* ====== Contact Section ====== */}
      <section className="donation-section">
        <div className="container">
        <div className="custom-heading">
            <h2>
              <span>Contact</span> Us
            </h2>
          </div>
          <div className="row"> 
          <div className="col-md-4">
          <div className="service-item">
                  <div className="service-icon">
                    <i className="fa fa-map-marker"></i>
                  </div>
                  <div className="service-content">
                    <h3>
                      Address
                    </h3>
                    <p><a href="">Vasant Nagari Ground, Vasant Nagar, Vasai East, Maharashtra 401209.</a></p>
                  </div>
                </div>
          </div>
          <div className="col-md-4">
          <div className="service-item">
                  <div className="service-icon">
                    <i className="fa fa-phone"></i>
                  </div>
                  <div className="service-content">
                    <h3>
                      Contact No
                    </h3>
                    <p><a href="tel:+918976796224">+91 8976796224 </a> / <a href="tel:+919326856896">+91 9326856896</a></p>
                    <p><a href="tel:++919923426688 ">+91  9923426688 </a> / <a href="tel:+917387938678">+91 7387938678</a></p>
                  </div>
                </div>
          </div>
          <div className="col-md-4">
          <div className="service-item">
                  <div className="service-icon">
                    <i className="fa fa-envelope"></i>
                  </div>
                  <div className="service-content">
                    <h3>
                      Mail
                    </h3>
                    <p><a href="mailto:bhagwat.vnvasai@gmail.com">bhagwat.vnvasai@gmail.com</a></p>
                  </div>
                </div>
          </div>
          </div>
        </div>
      </section>
      {/* ====== Contact Section ====== */}
        <section className="donation-section">
        <div className="container">
          <div className="custom-heading">
            <h2>
              <span>Get In </span> Tuch
            </h2>
          </div>
          <div className="row">
            <div className="col-12">
            <div className="dontion_form">
                    <div className="row">
                  <div class="col-md-4 col-sm-6 mb-2">
                    <div class="form-group">
                      <label class="mb-2" for="name">Full Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={custName}
                        onChange={(e) => setCustName(e.target.value)}
                      />
                      {errors.custName && (
                        <span className="error-text">{errors.custName}</span>
                      )}
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6 mb-2">
                    <div class="form-group">
                      <label class="mb-2" for="name">Your Email</label>
                      <input
                        class="form-control"
                        type="text"
                        name="email"
                        value={custEmail}
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                      {errors.custEmail && (
                        <span className="error-text">{errors.custEmail}</span>
                      )}
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6 mb-2">
                    <div class="form-group">
                      <label class="mb-2" for="name">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="contact"
                        value={custContact}
                        onChange={(e) => setCustContact(e.target.value)}
                      />
                      {errors.custContact && (
                        <span className="error-text">{errors.custContact}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div class="form-group">
                      <label class="mb-2" for="name">Message</label>
                      <textarea
                        class="form-control"
                        name="message"
                        value={custMessage}
                        rows={6}
                        onChange={(e) => setCustMessage(e.target.value)}
                      ></textarea>  
                      {errors.custMessage && (
                        <span className="error-text">{errors.custMessage}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div class="form-btn">
                      <button className="enquiry-btn" onClick={ServForm}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======= Donation Section ========= */}
      <div className="donate-strip">
        <div className="container">
          <ul>
            <li>
              <h3>
                give us <span> donation </span>
              </h3>
              <p>
                This is a gift. It has no cost, and it can be tremendously
                powerful.
              </p>
            </li>
            <li>
              
            <button
                      type="submit"
                      className=""
                     
                    >
                     <Link to="/Donation"> Donation Now</Link>
                    </button>
                    {/* <EnquiryModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    /> */}
            </li>
          </ul>
        </div>
      </div>
      {/* ====== footer Section ====== */}
      <Footer/>
    </>
  )
}

export default Contact;
