import React, { useState, useEffect } from "react";
import "../CSS/NavBar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../Img/logo.png";

const NavBar = () => {
  const [color, setColor] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  // Function to handle Navbar collapse
  const handleNavbarCollapse = () => {
    setExpanded(false);
  };

  return (
    <div className="nav-bar-component">
      <div className="NavSection">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="dark"
          className={color ? "NavBar NavBar-bg" : "NavBar"}
          expanded={expanded}
        >
          <Container>
            <Navbar.Brand href="#home">
              <Link to="/" onClick={handleNavbarCollapse}>
                <div className="NavLogo">
                  <img src={Logo} alt="" />
                </div>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setExpanded(!expanded)}
            />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-center"
            >
              <Nav>
                <Link to="/" onClick={handleNavbarCollapse}>
                  Home
                </Link>
                <Link to="/About" onClick={handleNavbarCollapse}>
                  About Us
                </Link>
                <Link to="/Event" onClick={handleNavbarCollapse}>
                  Event
                </Link>
                {/* <Link to="/Gallery" onClick={handleNavbarCollapse}>
                  Gallery
                </Link> */}
                <Link to="/Donation" onClick={handleNavbarCollapse}>
                Donation
                </Link>
                <Link to="/Contact" onClick={handleNavbarCollapse}>
                  Contact Us
                </Link>
              </Nav>
            </Navbar.Collapse>
            <div className="link-btn">
              <Link to="/Donation">Donate Now</Link>
            </div>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default NavBar;
