import React, { useState} from "react";
import "../CSS/Donation.css";

import { Button } from "antd";
import EnquiryModal from "../Components/EnquiryModal";

import Footer from "../Components/Footer";
import Table from "react-bootstrap/Table";
import { MetaTags } from "react-meta-tags";

import StudWork from "../Components/StudWork";
const Donation = () => {
 
  const [inputFieldValue, setInputFieldValue] = useState('');

  // Function to handle input field value change
  const handleInputChange = (event) => {
    setInputFieldValue(event.target.value);
  };
  // ============ Json  ==========
  const [selectedDonationValue, setSelectedDonationValue] = useState(null);

  // ============== Mail Print ======
  console.log(StudWork);
  console.log(selectedDonationValue);
  // popup Modal
  const [modalShow, setModalShow] = React.useState(false);
  const openModal = (donationValue) => {
    setSelectedDonationValue(donationValue);
    setModalShow(true);
  };
  return (
    <>
      {/* ========= SEO ========== */}

      <MetaTags>
        <title>Jrb Katha Vasai</title>
        <meta
          name="description"
          content="Join us in making a difference through food donation at sri Jalaram Bapa Annakshetra. Help us nourish lives and spread kindness to those in need."
        />

        <meta
          property="og:title"
          content="Food Donation at sri Jalaram Bapa Annakshetra"
        />
        <meta
          property="og:description"
          content="Join us in making a difference through food donation at sri Jalaram Bapa Annakshetra. Help us nourish lives and spread kindness to those in need."
        />
        <meta
          name="keywords"
          content="best place to donate food, food donation, food charity, food drive, charities that donate food, charities for feeding the hungry ,charities feeding the poor, charities that give food to the poor, best charities for feeding the poor, feed the poor, giving food to the needy"
        />
        <link
          rel="canonical"
          href="https://jrbannakshetra.com/food-donation-annakshetra"
        />
      </MetaTags>
      {/* ============== Donation Section1 =========== */}
      <div className="donationSection1">
        <div className="donationBanner">
          <div className="aboutTxt">
            <h2>Donation</h2>
            <ul>
              <li>
                <a href="/">Home</a> /
              </li>
              <li>Donation</li>
            </ul>
          </div>
        </div>
      </div>
      {/* ========== Upcoming Festival ======= */}

      {/* =============== Sponsorship list ======== */}
      <div className="services mt-5">
        <div className="container">
          <div className="row">
            <div className="donte-title">
              <h4>Sponsorship</h4>
            </div>
            <div className="col-md-6">
              <div className="donation_list">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Donate Amount</th>
                      <th>Donate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {StudWork[0].List.map((donationValue) => (
                      <tr>
                        <td class="meals">
                          <h5>{donationValue.Tname}</h5>
                        </td>

                        <td class="Donate-amount">
                          <h5>{donationValue.Tvalue}</h5>
                        </td>
                                 
                                 
    
      <td class="Donates-btn">
                          <EnquiryModal
                            show={modalShow}
                            donationValue={donationValue.Tvalue}
                          
                            onHide={() => setModalShow(false)}
                          />
                        </td>
                    
                      </tr>
                    ))}
                     
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-md-6">
              {/* <div className="donte-title">
                <h4>Donate in Festival Days</h4>
              </div> */}
              <div className="donation_list">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Donate Amount</th>
                      <th>Donate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {StudWork[1].List.map((donationValue) => (
                      <tr>
                        <td class="meals">
                          <h5>{donationValue.Tname}</h5>
                        </td>

                        <td class="Donate-amount">
                          <h5>{donationValue.Tvalue}</h5>
                        </td>
                        <td class="Donates-btn">
                          <EnquiryModal
                            show={modalShow}
                            donationValue={donationValue.Tvalue}
                          
                            onHide={() => setModalShow(false)}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>Donate any amount</td>
                      <td>
                      <input type="text" value={inputFieldValue}
      
        placeholder="Enter value..."
      />
                      </td>
                      <td class="Donates-btn">
                      <EnquiryModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          value={inputFieldValue}
                          onChange={handleInputChange}
                          />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --------------Advertisement list------------- */}
      {/* =============== Donation list ======== */}
      <div className="services mt-5">
        <div className="container">
          <div className="row">
            <div className="donte-title">
              <h4>Advertisement</h4>
            </div>

            <div className="donation_list">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Donate Amount</th>
                    <th>Donate</th>
                  </tr>
                </thead>
              
                <tbody>
                {StudWork[2].List.map((donationValue) => (
                  <tr>
                    <td class="meals">
                      <h5>{donationValue.Tname}</h5>
                      <p>
                      {donationValue.Tname2}
                      </p>
                      <p>{donationValue.Tname3}</p>
                    </td>
                    <td class="Donate-amount">
                      <h5>{donationValue.Tvalue}</h5>
                    </td>
                    <td class="Donates-btn">
                          <EnquiryModal
                            show={modalShow}
                            donationValue={donationValue.Tvalue}
                          
                            onHide={() => setModalShow(false)}
                          />
                        </td>
                  </tr>
                 ))}
                </tbody>
                
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Donation;
