import React from "react";
import "../CSS/About.css";
import Footer from "../Components/Footer";
import EnquiryModal from "../Components/EnquiryModal";
import {Link} from "react-router-dom";
const About = () => {
    // popup Modal
    const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      {/* ====== About Banner ======== */}
      <div className="donationSection1">
        <div className="donationBanner">
        <div className="aboutTxt">
          <h2>About Us</h2>
          <ul>
            <li>
              <a href="/">Home</a> /
            </li>
            <li>About Us</li>
          </ul>
        </div>
        </div>
      </div>
      {/* ======= About Section ==== */}
      <section className="about-section main-about">
        <div className="container">
          <div className="custom-heading">
            <h2>
              <span>sri  </span> Raghubar Dutt Tiwari
            </h2>
          </div>
          <p>Raghubar Dutt Tiwari is a spiritual preacher and mentor of Sanatan Dharma, spreading timeless spiritual cults across societies through our various ancient Vedic scriptures and cultures through spiritual discourses organised throughout the year and also rendering medical services since 35 years globally.</p>
          <p>On YouTube (Sri Raghubir Das Bhagwat Katha), various lectures and discourse series on various spiritual topics are available. Our mission is to help people in every possible way in terms of fulfilling their basic needs like food, clothing, and shelter, and thus help them appropriate God's holy names and make them happy. In the same line, we are deeply inspired by a great saint in Gujarat named Sri Jalaram Bapa.</p>
        </div>
      </section>
            {/* ======= Donation Section ========= */}
            <div className="donate-strip">
        <div className="container">
          <ul>
            <li>
              <h3>
                give us <span> donation </span>
              </h3>
              <p>
                This is a gift. It has no cost, and it can be tremendously
                powerful.
              </p>
            </li>
            <li>
            <button
                      type="submit"
                      className=""
                 
                    >
                      <Link to="/Donation">Donate Now</Link>
                    </button>
                    {/* <EnquiryModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    /> */}
            </li>
          </ul>
        </div>
      </div>
      {/* ====== footer Section ====== */}
      <Footer/>
    </>
  );
};

export default About;
