import Gallery1 from "../Img/gallery/img1.jpeg";
import Gallery2 from "../Img/gallery/img10.jpeg";
import Gallery3 from "../Img/gallery/img11.jpeg";
import Gallery4 from "../Img/gallery/img12.jpeg";
import Gallery5 from "../Img/gallery/img13.jpeg";
import Gallery6 from "../Img/gallery/img14.jpeg";
import Gallery7 from "../Img/gallery/img15.jpeg";
import Gallery8 from "../Img/gallery/img16.jpeg";
import Gallery9 from "../Img/gallery/img16.jpeg";

const photos = [
    {
        src:Gallery1,
        width:3,
        height:3,
      },
      {
        src:Gallery2,
        width:3,
        height:2,
      },
      {
        src:Gallery3,
        width:3,
        height:3,
      },
      {
        src:Gallery4,
        width: 3,
        height: 2,
      },
      {
        src:Gallery5,
        width:3,
        height:2,
      },
      {
        src:Gallery6,
        width:3,
        height:2,
      },
      {
        src:Gallery8,
        width:3,
        height:3,
      },
      {
        src:Gallery7,
        width:3,
        height:2,
      },
      {
        src:Gallery9,
        width:3,
        height:3,
      },
];

export default photos;