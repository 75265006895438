// ========= Room Images ==========
import Room1 from "../Img/banner/home-bg.jpg";
// =========== Resort Images ==========
import Resort1 from "../Img/banner/home-bg.jpg";
// ============ Pool Images ==========
import Beach1 from "../Img/banner/home-bg.jpg";
// =========== Food Images ==============
import Food1 from "../Img/banner/home-bg.jpg";

const GalleryData = [
  {
      id:1,
      Category:"khata",
      EImage:Room1,
      EventLink:"https://www.youtube.com/",
      EventName:"srimad Bhagwat Katha",
      EventDesc:"Vasant Nagari Ground, Vasant Nagar, Vasai East, Maharashtra 401209.",
  },
  {
      id:2,
      Category:"satsang",
      EImage:Resort1,
      EventLink:"https://www.youtube.com/",
      EventName:"srimad Bhagwat Katha",
      EventDesc:"Vasant Nagari Ground, Vasant Nagar, Vasai East, Maharashtra 401209.",
  },
  {
      id:3,
      Category:"temple",
      EImage:Beach1,
      EventLink:"https://www.youtube.com/",
      EventName:"srimad Bhagwat Katha",
      EventDesc:"Vasant Nagari Ground, Vasant Nagar, Vasai East, Maharashtra 401209.",
  },
  {
      id:4,
      Category:"bhandara",
      EImage:Food1,
      EventLink:"https://www.youtube.com/",
      EventName:"srimad Bhagwat Katha",
      EventDesc:"Vasant Nagari Ground, Vasant Nagar, Vasai East, Maharashtra 401209.",
  },
]

export default GalleryData;