import React, { useState,useLayoutEffect,useCallback } from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import "../CSS/Gallery.css";
import GalleryData from "../Components/GalleryData";
import Tabs from "./Tabs";
import Items from "./Items";
import  ProjectImages from "../Components/Photos";
import { Bounce } from 'react-reveal';
import Zoom from "react-reveal/Zoom";
import Footer from '../Components/Footer';
import EnquiryModal from "../Components/EnquiryModal";

const Gallerys = ({}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };



  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  const [data, setData] = useState(GalleryData)
  // Store Category in CategoryData 
  const categoryData = GalleryData.map((value) => {
    return value.Category
  });
  const tabsData = ["all", ...new Set(categoryData)];
  // for all categoryData set 
  const filterCategory = (Category) => {
    if (Category == "all") {
      setData(GalleryData)
      return;
    }
    const filteredData = GalleryData.filter((value) => {
      return value.Category == Category;
    })
    setData(filteredData);
  }
  // popup Modal
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      {/* ========== Gallery Banner ==== */}
      <div className="Gallery-banner">
      <div className="galleryTxt">
          <zoom><h2>Gallery</h2></zoom>
          <ul>
            <li>
              <a href="/">Home</a> /
            </li>
            <li>Gallery</li>
          </ul>
        </div>
      </div>
       {/* =========== Gallery images =========== */}
       <section className='Gallery-img'>
        <div className='Gallery-Card'>
          <div className='container'>
          <div className="custom-heading">
              <h2>
                <span>Img</span> Gallery
              </h2>
            </div>
            <div className='row'>
            <Gallery photos={ProjectImages} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={ProjectImages.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
            </div>
          </div>
        </div>
      </section>
      {/* =========== Gallery video =========== */}
      <section className='Gallery-video'>
        <div className='Gallery-Card'>
          <div className='container'>
          <div className="custom-heading">
              <h2>
                <span>Video</span> Gallery
              </h2>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className="gallerybtn">
                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                </div>
                <div className="galleryContent">
                <Items data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======= Donation Section ========= */}
      <div className="donate-strip">
        <div className="container">
          <ul>
            <li>
              <h3>
                give us <span> donation </span>
              </h3>
              <p>
                This is a gift. It has no cost, and it can be tremendously
                powerful.
              </p>
            </li>
            <li>
            <button
                      type="submit"
                      className="donate-btn"
                      onClick={() => setModalShow(true)}
                    >
                      Donation Now
                    </button>
                    {/* <EnquiryModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    /> */}
            </li>
          </ul>
        </div>
      </div>
      {/* ====== footer Section ====== */}
      <Footer/>
    </>
  );
};

export default Gallerys;
