import React from "react";
import { Link } from "react-router-dom";
import "../CSS/Footer.css";
import Logo from "../Img/logo.png";
import { FaDonate } from 'react-icons/fa';
const Footer = () => {
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="row">
              <div className="col-xl-8 col-md-8 mb-30">
              <div className="single-cta">
                  <i className="fa fa-phone" />
                  <div className="cta-text">
                    <h4>For Bhagwat Katha Related Queries</h4>
                    <a href="tel:+91 8976796224" target="_blank">
                      <span>8976796224 / 9326856896 / 9923426688 / 7387938678</span>
                    </a> 
                    {/* / 
                    <a href="tel:+91 8976796224" target="_blank">
                      <span>+91 89767962224</span>
                    </a> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fa fa-phone" />
                  <div className="cta-text">
                    <h4>Call us</h4>
                    <a href="tel:+91 992326688" target="_blank">
                      <span>+91 992326688</span>
                    </a> / 
                    <a href="tel:+91 7387938678" target="_blank">
                      <span>+91 7387938678</span>
                    </a>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fa fa-envelope-open" />
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <a href="mailto:bhagwat.vnvasai@gmail.com" target="_blank">
                      <span>bhagwat.vnvasai@gmail.com</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link href="/">
                      <img
                        src={Logo}
                        className="img-fluid"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>
                    This charitable trust was established in the holy presence of sri Raghubar Dutt Tiwari, under the provisions of the Indian Trust Act on 20th August 2018.            </p>
                  </div>
                  
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/About">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/Event">
                        Event
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/Gallery">
                      Gallery
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/Donation">
                      Donation
                      </Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Address</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                    Vasant Nagari Ground, Vasant Nagar, Vasai East, Maharashtra 401209.
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="https://www.facebook.com">
                      <i className="fa fa-facebook facebook-bg" />
                    </a>
                    <a href="https://youtu.be/-32gELyRtOE">
                      <i className="fa fa-youtube google-bg" />
                    </a>
                    <a href="https://www.instagram.com/">
                      <i className="fa fa-instagram google-bg" />
                    </a>
                    <a href="https://wa.me/+918976796224">
                      <i class="fa fa-whatsapp watsapp-bg" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright © 2023, All Right Reserved{" "}
                    <a href="https://skdm.in">
                      Khatakarak
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 text-center text-right">
              <div className="copyright-text">
                  <p>
                    Develope By : 
                    <a href="https://skdm.in">
                       Shree Krishna Digital Marketing
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="DonateBtn-con">
        
      <Link to="/Donation" className="DonateBtn"> 
          <FaDonate/>
          </Link>
      </div>
    </>
  );
};

export default Footer;
