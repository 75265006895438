  import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './Components/NavBar';
import Home from './Pages/Home.jsx';
import Gallery from './Pages/Gallery.jsx';
import About from './Pages/About.jsx';
import Event from "./Pages/Event.jsx";
import Donation from './Pages/Donation.jsx';
import Contact from './Pages/Contact.jsx';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Event" element={<Event />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Donation" element={<Donation />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );  
}

export default App;
