import React, {useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import "../CSS/Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";

import Gallery1 from "../Img/gallery/img1.jpeg";
import Gallery2 from "../Img/gallery/img4.jpeg";
import Gallery3 from "../Img/gallery/img3.jpeg";
import Gallery4 from "../Img/gallery/img5.jpeg";
import Gallery5 from "../Img/gallery/img6.jpeg";
import Gallery6 from "../Img/gallery/img7.jpeg";
import { Link } from "react-router-dom";
import AboutImg from "../Img/about/founder.jpg";
import lord from "../Img/lord-krishna-radha.jpg";
import EventImg from "../Img/banner/home-bg.jpg";

import Footer from "../Components/Footer";



const Home = () => {
  // ========== home Gallery ========
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = [Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  // popup Modal
  

  return (
    <>
      {/* ============ Banner Section =========== */}
      <div className="bannerSection">
        <Swiper
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="Home_Banner"
        >
          <SwiperSlide>
            <div className="home_Banner1">
              <div className="homeBanner_Txt">
              
                {/* <Zoom>
                  <h2>Luxury Living With Nature</h2>
                </Zoom> */}
              </div>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="home_Banner2">
              <div className="homeBanner_Txt">
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="home_Banner3">
              <div className="homeBanner_Txt">
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
      {/*======== Event Date Pages =======  */}
      {/* <div className="event-strip">
        <div className="container">
          <div className="date">
            <h2>14th</h2>
            <p>Jan, 2024</p>
          </div>
          <div className="row">
            <div className="col-md-5">
              <ul>
                <li>
                  <h3>sri Jalaram Bapa Foundation</h3>
                </li>
                <li>
                  <p>14 January to 20 January 2024</p>
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <ul>
                <li>
                  <p>Evening 4pm to 8pm</p>
                </li>
                <li>
                  <p>
                  Vasant Nagari Ground, Vasant Nagar, Vasai East,  Maharashtra 401209.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              <div className="link-btn">
                <Link to="/">
                  Read More{" "}
                  <i className="fa fa-angle-double-right" aria-hidden="true" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* ========= Trust Section ====== */}
      <section className="trust-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="custom-heading">
                <h2>sri Jalaram Bapa Foundation</h2>
                </div>
                <p style={{ textAlign: "justify" }}>
                Our trust is inspired by the life and teachings of sri Jalaram Bapa, a renowned saint from Gujarat, India who dedicated himself to serving the poor and needy. We aim to empower the rural population of India by providing them with education, health care, social justice, and food security. We believe that every human being deserves dignity and respect, regardless of their caste, creed, or religion. We also extend our humanitarian aid and charity to other parts of the world where people are suffering from hunger, poverty, and injustice.

Our founder, sri Raghubir Dutt Tiwariji (Prabhuji), is a spiritual preacher and mentor of Sanatan Dharma, the eternal way of life. He has been spreading the timeless spiritual values across societies and also rendering medical services for 35 years globally. He is a living example of compassion, wisdom, and devotion.
                </p>
                <div className="content">
                  <h3>Mission</h3>
                  <p>Our mission is to alleviate hunger, empower communities, and promote Indian Spiritual philosophy. Through our Food Distribution programs and the establishment of educational and healthcare institutions in rural India, we aim to create a brighter future for all, while fostering a deep understanding and appreciation of Sanatan Dharma.</p>
                </div>
                <div className="content">
                  <h3>Vision</h3>
                  <p>We envision a world where no one goes hungry, where every individual ha access to quality education and healthcare, and where the wisdom of Indian Spiritual philosophy is embraced and shared. By weaving together the threads of compassion, knowledge, and spirituality, we strive to create a harmonious and prosperous society that uplifts and nurtures every soul.</p>
                </div>
           
            </div>

            <div className="col-md-6 col-12">
              <div className="homeAbout">
                <img src={lord} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ====== About Section ====== */}
      <section className="about-section">
        <div className="container">
          <div className="custom-heading">
            <h2>
              <span> sri</span> Raghubar Dutt Tiwari
            </h2>
          </div>
          <ul>
            <li>
              <div className="homeAbout">
                <img src={AboutImg} alt="" />
              </div>
            </li>
            <li>
              <p>
                Raghubar Dutt Tiwari is a spiritual preacher and mentor of
                Sanatan Dharma, spreading timeless spiritual cults across
                societies through our various ancient Vedic scriptures and
                cultures through spiritual discourses organised throughout the
                year and also rendering medical services since 35 years
                globally.
              </p>
              <p>
                On YouTube{" "}
                <Link to="https://youtu.be/KtV8Au1uPmQ">
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "1.2",
                    }}
                  >
                    (Sri Raghubir Das Bhagwat Katha)
                  </span>
                </Link>
                , various lectures and discourse series on various spiritual
                topics are available. Our mission is to help people in every
                possible way in terms of fulfilling their basic needs like food,
                clothing, and shelter, and thus help them appropriate God's holy
                names and make them happy. In the same line, we are deeply
                inspired by a great saint in Gujarat named Sri Jalaram Bapa.
              </p>
              <div className="dark-btn">
                <Link to="/About">
                  Read More{" "}
                  <i className="fa fa-angle-double-right" aria-hidden="true" />
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </section>
      {/* ======== Event Section ============ */}
      <section className="eventSection">
        <div className="container">
          <div className="custom-heading">
            <h2>
              <span>Latest</span> events
            </h2>
          </div>
          <div className="row">
            <div className="col-md-6 col-12">
           
            <div className="left">
             
                <img src={EventImg} alt="" />
               
             
             
            </div>
         
          </div>
          <div className="col-lg-6 col-12">
          <div className="right">
              <div className="event-slider">
                {/* <div className="slick-slide">
                  <figure>
                    <img
                      src={lord}
                      alt=""
                    />
                    
                  </figure>
                
                </div> */}
                <div className="content">
                  <h3>srimad Bhagwat Katha</h3>
                  <ul>
                    <li>
                      <strong>Kathavyas: </strong>Shree Raghubar Dutt Tiwari
                    </li>
                    <li>
                      <strong>DATE: </strong> 14th Jan 2024 to 21th Jan 2024
                    </li>
                    <li>
                      <strong>TIME: </strong> Evening 4pm to 8pm
                    </li>
                    <li>
                      <strong>venue: </strong>Vasant Nagari Ground, Vasant
                      Nagar, Vasai East, Maharashtra 401209.
                    </li>
                    <li>
                      <strong>Kalashyatra: </strong> 14 Jan 2024 at 4pm
                    </li>
                    <li>
                      <strong>Bhandara: </strong> 21 Jan 2024 from 10am
                    </li>
                  </ul>
                </div>
                {/* <div className="slick-slide">
                  <figure>
                    <img
                      src={EventImg}
                      alt=""
                    />
                    <div className="date">
                      <span>14th</span>
                      Jan 2024
                    </div>
                  </figure>
                  <div className="content">
                    <h3>srimad Bhagwat Katha</h3>
                    <ul>
                      <li>
                        <strong>DATE: </strong> 14th Jan 2024 to 21th Jan 2024
                      </li>
                      <li>
                        <strong>TIME: </strong> Evening 4pm to 8pm
                      </li>
                      <li>
                        <strong>venue: </strong>Vasant Nagari Ground, Vasant Nagar, Vasai East,  Maharashtra 401209.
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
              </div>
          </div>
        
      
         
        </div>
      </section>
      {/* ====== Gallery ======= */}
      <section className="gallery-section">
        <div className="container">
          <div className="custom-heading">
            <h2>
              <span> Images</span> gallery
            </h2>
          </div>
          <div className="row">
            {images.map((image, index) => (
              <div className="col-md-4 mb-md-3 mb-3" key={index}>
                <div
                  className="home_gallery"
                  onClick={() => openLightbox(index)}
                >
                  <img src={image} alt="" />
                </div>
              </div>
            ))}
            {lightboxOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={closeLightbox}
                onMovePrevRequest={() =>
                  setPhotoIndex(
                    (photoIndex + images.length - 1) % images.length
                  )
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % images.length)
                }
              />
            )}
          </div>
        </div>
      </section>

      {/* ====== video Section ====== */}
      <section className="video-section">
        <div className="container">
          <div className="custom-heading">
            <h2>
              <span></span> Video gallery
            </h2>
          </div>
          {/* <div className="big-video">
            <div>
              <iframe
                src="https://www.youtube.com"
                frameBorder={0}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""
              />
            </div>
          </div> */}
          <div className="row mt-md-5 mt-3">
            <div className="col-lg-3 col-sm-6 mb-3">
              <div className="video_img">
                  <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/KtV8Au1uPmQ"
                  title="Sri Raghubir Das Prabhuji-Sri Ram Katha , Vasai (West) Mumbai 2015 (Yagna Day) 2nd part"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                {/* <img
                  src={Gallery1}
                  alt=""
                /> */}
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-3">
              <div className="video_img">
                   <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/x7lY4X54SMk"
                  title="Raghubir Das prbhuji srimad-Bhagwatam katha  pune"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                {/* <img
                  src={Gallery2}
                  alt=""
                /> */}
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-3">
              <div className="video_img">
                  <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/PGNoJa-marc"
                  title="Sri Raghubir Das Prabhuji-Sri Bhagwad Katha - Vasai (Mumbai) - 2010"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                {/* <img
                  src={Gallery3}
                  alt=""
                /> */}
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-3">
              <div className="video_img">
                    <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/ZpME-iRqTxA"
                  title="Sri Raghubir Das Prabhuji-Sri Ram Katha , Vasai (West) Mumbai 2015 (Yagna Day) 2nd part"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                {/* <img
                  src={Gallery4}
                  alt=""
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======= Donation Section ========= */}
      <div className="donate-strip">
        <div className="container">
          <ul>
            <li>
              <h3>
                give us <span> donation </span>
              </h3>
              <p>
                This is a gift. It has no cost, and it can be tremendously
                powerful.
              </p>
            </li>
            <li>
              <Link to="/Donation">
                {" "}
                <button type="submit" className="">
                  Donate Now
                </button>
              </Link>
              {/* <EnquiryModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    /> */}
            </li>
          </ul>
        </div>
      </div>
      {/* ====== footer Section ====== */}
      <Footer />
    </>
  );
};

export default Home;
