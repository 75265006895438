import React, { useState } from "react";
// import Resizer from "react-image-file-resizer";
import { useImageResizer } from "../Context/imageResizer";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
// import $ from "jquery";
import "../CSS/Donation.css";
import scanner from "../Img/scan.jpeg";
const EnquiryModal = ({donationValue}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // -----------------image resizer--------------------
  const [profileFile, setProfileFile] = useState(null);
  const { resizeFile } = useImageResizer();
  const handleImageUpload = async (event) => {
    const maxWidth = 600;
    const maxHeight = 600;
    const targetFileSize = 60 * 1024;

    const compresedFile = await resizeFile(
      event.target.files[0],
      maxWidth,
      maxHeight,
      "WEBP",
      targetFileSize
    );
    setProfileFile(compresedFile);
    console.log(URL.createObjectURL(compresedFile));
  };

  console.log(profileFile);

  // email
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const formData = new FormData();
    const addressDetails =
      values.address +
      " " +
      values.city +
      " " +
      values.state +
      " " +
      values.pinCode;
      const amountValue = values.amount || donationValue;
    const body =
      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Jrb Katha Donation</h2><p>Hello</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">' +
      values.fullName +
      '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">' +
      values.email +
      '</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">' +
      values.phone +
      '</td></tr><tr><td style="text-align:left"><strong>PAN no.:</strong></td><td style="text-align:left">' +
      values.panNumber +
      '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Address:</strong></td><td style="text-align:left">' +
      addressDetails +
      '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Donation:</strong></td><td style="text-align:left">' +
      amountValue +
      '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">' +
      values.message +
      '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Katha Vasai</p></div></body></html>';

    formData.append("mailBody", body);
 
    formData.append("toEmail", "bhagwat.vnvasai@gmail.com");
 
    formData.append("fromEmail", "skdmlead@gmail.com");
    formData.append("bccMail", "skdmlead@gmail.com");
    formData.append("mailSubject", "New donation from website");
    formData.append("accountName", "katha");
    formData.append("accountLeadSource", "https://skdm.in");
    formData.append("accountLeadName", "custName");

    const resp = await fetch("https://skdm.in/server/v1/send_lead_mail.php", {
      method: "post",
      body: formData,
    });
    alert("Your Form has Submited Our team will contact with You  soon.");
    console.log(resp);
    console.log(addressDetails);
    form.resetFields();
    setIsModalOpen(false);
  };

  console.log("donationValue",donationValue);

  return (
    <>
      <Button type="danger" className="btn" onClick={showModal} >
        Donate
      </Button>

      <Modal
        title="Donation form"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[]}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="scanner">
                <img src={scanner} alt="" />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <Form.Item
                      name="fullName"
                      label="Full Name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Full Name" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6 col-12">
                    <Form.Item
                      name="phone"
                      label="Phone"
                      rules={[
                        { required: true },
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Please enter a valid phone number",
                        },
                      ]}
                      tooltip={{
                        title: "Tooltip with customize icon",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Input placeholder="+91" />
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-12">
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[{ type: "email", required: true }]}
                      tooltip={{
                        title: "Tooltip with customize icon",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Input placeholder="abc@email.com" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6 col-12">
                    <Form.Item
                      name="panNumber"
                      label="PAN no."
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="CVNPP1234A" />
                    </Form.Item>
                  </div>
                </div>

                <Form.Item
                  name="address"
                  label="Address"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea placeholder="Enter your address" />
                </Form.Item>

                <div className="row">
                  <div className="col-md-4 col-12">
                    <Form.Item
                      name="city"
                      label="City"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="City" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4 col-12">
                    <Form.Item
                      name="state"
                      label="State"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="State" />
                    </Form.Item>
                  </div>
                  <div className="col-md-4 col-12">
                    <Form.Item
                      name="pinCode"
                      label="Pin Code"
                      rules={[
                        { required: true },
                        {
                          pattern: /^[0-9]{6}$/,
                          message: "Please enter a valid pin code",
                        },
                      ]}
                    >
                      <Input placeholder="Pin Code" />
                    </Form.Item>
                  </div>
                  <div className="col-12">
                    <Form.Item
                      name="message"
                      label="Message"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea placeholder="Message" />
                    </Form.Item>
                  </div>
                  <div className="col-sm-12 form-group">
                    <Form.Item name="amount" label="Donation">
                      <input
                        type="number"
                        class="form-control"
                        name="amt"
                        id="name-l"
                         
                        placeholder={donationValue}
                        disabled
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-sm-12 form-group">
                  <Form.Item
                    name="donation"
                    label="Upload Sreenshot of payment"
                  >
                    <input
                      type="file"
                      class="form-control"
                      // onChange={handleFileChange }
                      onChange={handleImageUpload}
                      accept=".JPG, .PNG, .JPEG, .WEBP, .AVIF"
                      // You can add additional attributes like accept to specify the types of files allowed
                      // For example: accept=".pdf,.doc,.docx"
                    />
                  </Form.Item>
                  {/* {selectedFile && <p>Selected File: {selectedFile.name}</p>} */}
                </div>

                <Form.Item>
                  <Button htmlType="submit" className="">
                    Donate Now
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EnquiryModal;
