import React from "react";
import "../CSS/Event.css";
import EventImg from "../Img/banner/home-bg.jpg";
import Footer from "../Components/Footer";
import Gallery3 from "../Img/gallery/img3.jpeg";

import EventImg1 from "../Img/kirtan.jpeg";
import EventImg2 from "../Img/kalash yatra.jpeg";
import EventImg3 from "../Img/bhagwat.jpeg";
import { Link } from "react-router-dom";
const Event = () => {
   
  return (
    <>
      {/* ====== Event Banner ======== */}
      <div className="donationSection1">
        <div className="donationBanner">
        <div className="aboutTxt">
          <h2>Events</h2>
          <ul>
            <li>
              <a href="/">Home</a> /
            </li>
            <li>Events</li>
          </ul>
        </div>
        </div>
      </div>
      {/* ======= Event Section ==== */}
      <section className="event-page">
        <div className="eventSection">
          <div className="container">
            <div className="custom-heading">
              <h2>
                <span>All</span> events
              </h2>
            </div>
            <div className="row">
              
              <div className="col-md-4">
            
            <div className="Event_img">
              <img src={EventImg1} alt="" />
            </div>
           
              </div>
              <div className="col-md-4">
             
            <div className="Event_img">
              <img src={EventImg2} alt="" />
            </div>
          
              </div>
              
              <div className="col-md-4">
             
             <div className="Event_img">
               <img src={EventImg3} alt="" />
             </div>
           
               </div>
          </div>
            <div className="row mt-5">
              
                <div className="col-md-4">
              
              <div className="Event_img">
                <img src={EventImg} alt="" />
              </div>
             
                </div>
                <div className="col-md-4">
               
              <div className="Event_img">
                <img src={Gallery3} alt="" />
              </div>
            
                </div>
                
                
            </div>
          </div>
        </div>
      </section>

      {/* ======= Donation Section ========= */}
      <div className="donate-strip">
        <div className="container">
          <ul>
            <li>
              <h3>
                give us <span> donation </span>
              </h3>
              <p>
                This is a gift. It has no cost, and it can be tremendously
                powerful.
              </p>
            </li>
            <li>
            <button
                      type="submit"
                      className="donate-btn"
                     
                    >
                     <Link to="/Donation"> Donation Now</Link>
                    </button>
                    {/* <EnquiryModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    /> */}
            </li>
          </ul>
        </div>
      </div>
      {/* ====== footer Section ====== */}
      <Footer/>
    </>
  );
};

export default Event;
