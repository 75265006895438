import React, { useCallback, useState } from "react";
import Zoom from "react-reveal/Zoom";
import { Link } from "react-router-dom";
const Items = ({ data }) => {

  return (
    <>
    <div className="GalleryItems">
    <div className="row">
        {data.map((value) => {
          const { id, EImage,EventLink, EventName, EventDesc } = value;
          return (
            <>
              <Zoom>
                <div className="col-md-4 col-sm-6 my-3" key={id}>
                  <Link to={EventLink}>
                  <div className="GalleryItem-cards text-center">
                    <div className="GalleryItem-cardImg">
                      <img src={EImage} />
                      <div className="GalleryItem-cardTxt">
                        <h4>{EventName}</h4>
                        <p><span>Address:</span> {EventDesc}</p>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </Zoom>
            </>
          );
        })}
      </div>
    </div>
    </>
  );
};

export default Items;
